exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-digital-diary-tsx": () => import("./../../../src/pages/digital_diary.tsx" /* webpackChunkName: "component---src-pages-digital-diary-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-foerderung-tsx": () => import("./../../../src/pages/info/foerderung.tsx" /* webpackChunkName: "component---src-pages-info-foerderung-tsx" */),
  "component---src-pages-info-termine-tsx": () => import("./../../../src/pages/info/termine.tsx" /* webpackChunkName: "component---src-pages-info-termine-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-projekte-audiovisual-joy-tsx": () => import("./../../../src/pages/projekte/audiovisual_joy.tsx" /* webpackChunkName: "component---src-pages-projekte-audiovisual-joy-tsx" */),
  "component---src-pages-projekte-der-gelbe-tsx": () => import("./../../../src/pages/projekte/der_gelbe.tsx" /* webpackChunkName: "component---src-pages-projekte-der-gelbe-tsx" */),
  "component---src-pages-projekte-die-zukunft-finden-tsx": () => import("./../../../src/pages/projekte/die_zukunft_finden.tsx" /* webpackChunkName: "component---src-pages-projekte-die-zukunft-finden-tsx" */),
  "component---src-pages-projekte-fensterprosa-tsx": () => import("./../../../src/pages/projekte/fensterprosa.tsx" /* webpackChunkName: "component---src-pages-projekte-fensterprosa-tsx" */),
  "component---src-pages-projekte-hybrid-11-tsx": () => import("./../../../src/pages/projekte/hybrid11.tsx" /* webpackChunkName: "component---src-pages-projekte-hybrid-11-tsx" */),
  "component---src-pages-projekte-kiss-the-cook-tsx": () => import("./../../../src/pages/projekte/kiss_the_cook.tsx" /* webpackChunkName: "component---src-pages-projekte-kiss-the-cook-tsx" */),
  "component---src-pages-projekte-reset-the-forest-tsx": () => import("./../../../src/pages/projekte/reset_the_forest.tsx" /* webpackChunkName: "component---src-pages-projekte-reset-the-forest-tsx" */),
  "component---src-pages-projekte-silver-linings-tsx": () => import("./../../../src/pages/projekte/silver_linings.tsx" /* webpackChunkName: "component---src-pages-projekte-silver-linings-tsx" */),
  "component---src-pages-projekte-wortfindungsamt-tsx": () => import("./../../../src/pages/projekte/wortfindungsamt.tsx" /* webpackChunkName: "component---src-pages-projekte-wortfindungsamt-tsx" */),
  "component---src-pages-projekte-wunder-welle-tsx": () => import("./../../../src/pages/projekte/wunder_welle.tsx" /* webpackChunkName: "component---src-pages-projekte-wunder-welle-tsx" */),
  "component---src-pages-projekte-wunderkammer-360-tsx": () => import("./../../../src/pages/projekte/wunderkammer_360.tsx" /* webpackChunkName: "component---src-pages-projekte-wunderkammer-360-tsx" */),
  "component---src-pages-projekte-yonder-tsx": () => import("./../../../src/pages/projekte/yonder.tsx" /* webpackChunkName: "component---src-pages-projekte-yonder-tsx" */),
  "component---src-templates-digital-diary-tsx": () => import("./../../../src/templates/digital_diary.tsx" /* webpackChunkName: "component---src-templates-digital-diary-tsx" */)
}

